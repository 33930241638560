.general-container {
    /* 水平居中 */
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 30px 20px 10px;
    /* background-color: rgb(217, 225, 227); */
    display: flex;
    justify-content: center;
}


.info-container {
    background-color: white;
    width: 504px;
    padding: 24px 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

.temp-notes {
    font-family: "Open Sans", sans-serif;
    color: rgb(57, 55, 55);
    font-weight: bold;
    margin-bottom: 20px;
}

.pic-box{
    position: relative;
    text-align: center;
    overflow: hidden; /* 隐藏溢出的内容 */
    height: 100vh;
    /* display: flex;
    justify-content: center; */
}


.whatsapp-pic {
    width: 100%; /* 宽度撑满容器 */
    height: auto; /* 高度自动，保持图片比例 */
    transform: translateY(-20%); /* 向上移动图片的50%，以显示中间部分 */
    top: 50%; /* 从容器的顶部50%的位置开始显示图片 */
}

.icon {
    position: absolute;
    bottom: 4vh; /* 使用负值将logo下移，值为你的logo实际高度 */
    left: 50%;
    transform: translateX(-50%) scale(3);
    left: 50%;
}