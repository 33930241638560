.general-container {
    /* 水平居中 */
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 30px 20px 10px;
    /* background-color: rgb(217, 225, 227); */
    display: flex;
    justify-content: center;
}


.info-container {
    align-items: center;
    background-color: white;
    width: 504px;
    padding: 24px 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

.temp-notes {
    font-family: "Open Sans", sans-serif;
    color: rgb(57, 55, 55);
    font-weight: bold;
    margin-bottom: 20px;
}

.pic-box{
    position: relative;
    text-align: center;
    overflow: hidden; /* 隐藏溢出的内容 */
    height: 100vh;
    /* display: flex;
    justify-content: center; */
}
