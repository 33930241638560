.page-one {
    background: linear-gradient(to right, #BFFEEB, white);
    height: 90vh;
}

a {
    text-decoration: none; /* 这会移除下划线 */
}

.container {
    /* 水平居中 */
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 30px 20px 10px;
    /* background-color: rgb(217, 225, 227); */

}


.area-top {
    display: flex;
    width: 100%;
    /* height: 548px; */
    /* background-color: rgb(231, 191, 191); */
}

.top-box-left {
    max-width: 40%;
    /* height: 548px;  */
    /* background: rgb(176, 176, 219);  */
    padding-right: 20px;
}

.top-box-right {
    max-width: 60%;
    /* height: 548px;  */
    /* background: rgb(133, 213, 207);  */
    padding-right: 20px;
}


.decor-box {
    padding: 0 0 10px;
    /* background-color: rgb(136, 83, 83); */
    height: 70.19px;
}

.top-left-texts {
    font-size: 48px;
    /* 字要左对齐 */
    text-align: left;
    font-family: "Open Sans", sans-serif;
    margin: 0 0 25.2px;

}

.normal-text-box {
    font-size: 18px;
    /* 字要左对齐 */
    text-align: left;
    font-family: "Open Sans", sans-serif;
    margin: 0 0 25.2px;
    /* background-color: rgb(118, 115, 115); */
    /* height: 99px; */
}

.button-box {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;      /* 使用Flexbox布局 */
    align-items: center; /* 垂直居中子项 */
    /* justify-content: center; */
    /* margin: 10px;
    padding: 0 250px 15px 0; */
}

.button {
    display: flex; /* Enable flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    text-align: center;
    font-size: 17px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    /* margin: 10px; */
    padding: 8px 10px;
    color: white;
    background-color: #00D093;
    border-radius: 20px;
    cursor: pointer;
    border: none; /* Remove border if it exists */
    outline: none; /* Remove focus outline if it exists */

}


.chrome-logo {
    display: block;
    width: 70px; /* Adjust width as necessary */
    /* Adjust vertical margin as necessary to align it properly */
    margin: 0 5px 0 0;
}


.icon {
    margin-left: 20px;
    font-size: 40px; 
    color:#5C64F4
}

.text {
    display: inline-block;
    /* 使文本在同一行上显示 */
    margin-right: 35px;
    /* 可选的右侧间距，以分隔文本 */

}
.textSpecial {
    /* 创建线性渐变背景 */
    background: linear-gradient(to right, #23554b, #d2aa1c);

    /* 设置文本的背景为上面定义的渐变 */
    -webkit-background-clip: text;
    background-clip: text;

    /* 将文本颜色设置为透明，这样背景渐变就可以显示出来 */
    -webkit-text-fill-color: transparent;
    color: transparent;
    
    font-weight: bold;
    display: inline-block;
    /* 使文本在同一行上显示 */
    margin-right: 95px;
}

.top-right-hero {
    max-width: 100%;
}

.blinking-text {
    font-size: 48px;
    font-weight: bold;
}

.blinking-line {
    display: inline-block;
    animation: blink .7s infinite;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.15;
    text-align: center;
}

.color-text {
    color: #274e13;
    padding: 0 0 5px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;

}


.area-rolling {
    /* 这里要不要加 width： 100%  */
    height: 150px;
    padding: 0 20px 25px;
    margin-top: 10px;
    /* height: 150px; */
    padding: 20px 20px 10px;
}

.rolling-box {
    position: relative;
    margin: 0 auto;
}

.companyLogo {
    margin: 50px 0;
    width: 120px;
    box-shadow: 3px 6px 8px #dde0e8;
}


.area-instruct1 {
    display: flex;
    width: 100%;
    /* height: 548px; */
    background-color: rgb(231, 191, 191);
}

.instruction-text-box {
    background:#5C64F4;
    max-width: 40%;
    height: 548px; 
    /* background: rgb(176, 176, 219);  */
    padding-right: 20px;
}
.instruction-video-box {
    max-width: 60%;
    /* height: 548px;  */
    background: rgb(133, 213, 207); 
    padding-right: 20px;

}



.page-two {
    margin: 0;
    background: linear-gradient(to right, #BFFEEB, white);
    height: 100vh;
}


.top-box-text {
    max-width: 40%;
    /* height: 548px;  */
    /* background: rgb(176, 176, 219);  */
    margin-left: 0px;
    padding-left: 100px;
    
}


.top-box-pic {
    max-width: 60%;
    /* height: 548px;  */
    /* background: rgb(133, 213, 207);  */
    /* padding-right: 20px; */

}


.top-left-hero {
    max-width: 100%;
}


.top-right-texts {
    font-size: 40px;
    /* 字要左对齐 */
    text-align: left;
    font-family: "Open Sans", sans-serif;
    margin: 0 0 40px;

}