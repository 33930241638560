.nav-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 82px;
    margin: 0px auto;
    max-width: 1240px;
    /* display: flex; */
    /* background-color: rgb(218, 198, 168); */
    align-items: center;
    padding: 0 20px;

}

.nav-menu {
    /* 这个位置希望logo 靠左， 还未实现 */
    background: transparent;
    width: 80%;
    height: 82px;
    /* 这个位置是随便写的！！margin */
    align-items: center;
    /* 垂直居中对齐 */
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

.logo {
    width: 120px;
    margin: 0 42px 0 0;
    height: 60px;
}

.button {
    border-radius: 5px;
    cursor: pointer;
    font-size: 14.5px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    background-color: #00D093;
    height: 46.84px;
    width: 145.16px;
    margin-left: auto;
    color: #fff;

}