* {
    box-sizing: border-box;
}

.plans-container {
    background-color: #fff;
    padding: 30px;
    display: flex;
    min-height: 100vh;
    justify-content: center
}

.plan-items {
    position: relative;
    border: 2px solid #1677ff;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 10px;
    text-align: center;
    min-height: 300px;
    height: fit-content;
    width: 200px;
    flex-shrink: 0;
    margin-right: 50px;
    padding-bottom: 50px;
}

.plan-items:hover {
    border: 2.5px solid blue;
}

.plan-name {
    font-weight: bold;
    font-size: 20px;
}

.plan-desc {
    color: #666;
    font-size: 15px;
    margin-top: 12px;
}

.plan-points {
    font-weight: bold;
    color: #191919;
    margin-top: 12px;
}

.plan-price {
    color: rgb(46, 142, 46);
    font-size: 20px;
    font-weight: bolder;
    margin-top: 20px;
}

.plan-hot {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    color: #ff3c3c;
}

.footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    left: 0;
}