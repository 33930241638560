.whole-container {
    /* 水平居中 */
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 30px 20px 10px;
    height: 100vh;
    /* background-color: rgb(217, 225, 227); */
}


.video {
align-items: center;
}

.normal-text {
font-family: "Open Sans", sans-serif;
font-weight: bold;
}

.img{
    width: 800px;
}

.block {
    margin-top:80px;
}